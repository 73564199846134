import axios from 'axios';
import md5 from 'blueimp-md5';
import { trim } from 'lodash';

const utils = {
  getGravatarURL: email => {
    let hash = md5(trim(email))
    return `https://www.gravatar.com/avatar/${hash}`;
  },

  getOpenGraphImageFromURL: url => {
    return axios.get('https://panel.izlesene.com/api/cometImageFetch?url=' + encodeURIComponent(url)).then(res => res.data);
  },

  uploadImage: file => {
    const formData = new FormData();
    formData.append('test', file, file.filename);
    return axios.post('https://panel.izlesene.com/api/cometImageUpload', formData).then(res => res.data);
  }
};

export default utils;