import { Snackbar, Alert } from '@mui/material';
import React from 'react';

const SnackbarNotification = ({ open, handleClose, message, ope }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={message === 0 ? 'error' : 'success'}
        sx={{ width: '100%' }}
      >
        {ope === 0 && message === 0 && 'There was a problem deleting message'}
        {ope === 0 && message === 1 && 'Message deleted successfully'}
        {ope === 1 && message === 0 && 'There was a problem updating message'}
        {ope === 1 && message === 1 && 'Message updated successfully'}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
