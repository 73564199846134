const applicationTypeMap = {
  0: {
    color: 'blue',
    item: 'data1',
    label: 'Desktop Website',
  },
  1: {
    color: 'pink',
    item: 'data2',
    label: 'Mobile Website',
  },
  2: {
    color: 'green',
    item: 'data3',
    label: 'Android Application',
  },
  3: {
    color: 'grey',
    item: 'data4',
    label: 'iOS Application',
  },
};

const partitions = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];

const partitionGroups = {
  all: partitions,
  canary: [partitions[Math.floor(Math.random() * 16)]],
  multivariate: {
    a: partitions.slice(0, 8),
    b: partitions.slice(8),
  },
};

const notification = {
  getApplicationType: type => applicationTypeMap[type],

  getPartitionGroup: type => partitionGroups[type],

  preview: notification => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        new Notification(notification.title, {
          body: notification.body || null,
          icon: notification.icon || null,
          image: notification.image || null,
          requireInteraction: true,
        });
      } else {
        alert(
          'You must allow notifications on this site for this feature to work!',
        );
      }
    });
  },
};

export default notification;
