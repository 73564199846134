import { isEmpty } from 'lodash';

import { AuthActions } from '../actions/auth';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null
};

function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AuthActions.AUTH_LOGIN:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
    case AuthActions.AUTH_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default { auth };
