export const AuthActions = {
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGOUT: 'AUTH_LOGOUT'
};

export const AuthActionCreators = {
  login: user => {
    return { type: AuthActions.AUTH_LOGIN, user };
  },
  logout: () => {
    return { type: AuthActions.AUTH_LOGOUT };
  }
};
