import * as React from 'react';

import { Dimmer, Grid, Page } from 'tabler-react';

export default class Loading extends React.Component {
  render() {
    return (
      <Page.Content title=" ">
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Dimmer active loader />
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    );
  }
}
