import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Card, Form, Grid, Page, ProgressCard } from 'tabler-react';

import Loading from '../components/Loading';
import APIManager from '../managers/APIManager';
import HelperNotification from '../helpers/notification';
import HelperUtils from '../helpers/utils';
import moment from 'moment-timezone';

class ComposePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partitionsDispatched: 0,
      partitionsToDispatch: 0,
      product: null,
      redirectTo: null,
      actionDesktop: '',
      actionMobile: '',
      applicationTypes: [],
      content: '',
      deliveryMethod: 'all',
      multivariateGroup: 'a',
      partitionGroup: HelperNotification.getPartitionGroup('all'),
      image: null,
      images: {},
      scheduleDate: '',
      scheduleTime: '',
      title: '',
      toggleDesktop: false,
      toggleMobile: false,
      toggleSchedule: false,
      urlDesktop: '',
      urlMobile: '',
      wait: false,
    };
  }

  componentWillMount() {
    APIManager.getProductApplications(this.props.match.params.product).then(
      res => {
        let applicationTypes = res.data.applications.map(
          _application => _application.type,
        );
        this.setState({
          applicationTypes,
          product: res.data,
        });
      },
    );
  }

  onImageSelect(e) {
    if (!e) return;

    let images = this.state.images;
    if (e.target.checked) {
      for (let i in images) {
        images[i].selected = images[i].url === e.target.value;
      }
      this.setState({ image: e.target.value, images });
    } else {
      for (let i in images) {
        images[i].selected = false;
      }
      this.setState({ image: null, images });
    }
  }

  onFileChange(e) {
    if (!e || !e.target.files.length) return;
    HelperUtils.uploadImage(e.target.files[0]).then(image => {
      if (image.url) {
        let images = this.state.images;
        for (let i in images) {
          images[i].selected = false;
        }
        images.custom = {
          selected: true,
          url: image.url,
        };
        this.setState({
          image: image.url,
          images,
        });
      }
    });
  }

  onPlatformURLBlur(e) {
    if (!e || !e.target.value.length) return;

    if (typeof this.state.images[e.target.value] === 'undefined') {
      let pageURL = e.target.value;
      HelperUtils.getOpenGraphImageFromURL(pageURL).then(image => {
        if (image.url) {
          let images = this.state.images;
          images[pageURL] = {
            selected: false,
            url: image.url,
          };
          this.setState({ images });
        }
      });
    }
  }

  onPlatformURLChange(e) {
    if (!e) return;
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    if (!e || this.state.wait) return;
    e.preventDefault();

    this.setState({ wait: true });
    let message = {
      product: this.state.product.id,
      payload: {
        actionDesktop: this.state.actionDesktop,
        actionMobile: this.state.actionMobile,
        content: this.state.content ? this.state.content : this.state.title,
        icon: this.state.product.applications[0].icon,
        image: this.state.image,
        title: this.state.title,
        urlDesktop: this.state.urlDesktop,
        urlMobile: this.state.urlMobile,
        scheduleDate: this.state.toggleSchedule
          ? moment(this.state.scheduleDate)
              .tz('Asia/Istanbul')
              .utc()
              .format('YYYY-MM-DD HH:mm:00')
          : '',
      },
      partitionsTargeted: this.state.partitionGroup.join(''),
    };

    APIManager.createMessage(message).then(res => {
      let redirectTo = `/products/${message.product}/messages/${res.data.id}`;
      this.setState({
        partitionsToDispatch:
          this.state.partitionGroup.length * res.data.notifications.length,
        redirectTo,
      });
      if (this.state.toggleSchedule) {
        this.setState({
          partitionsDispatched:
            this.state.partitionGroup.length * res.data.notifications.length,
        });
        return;
      }

      for (let notification of res.data.notifications) {
        this.state.partitionGroup.map((partition, index) => {
          setTimeout(
            (that, notification, partition) => {
              APIManager.dispatchNotification(notification.id, partition)
                .then(() => {
                  that.setState({
                    partitionsDispatched: that.state.partitionsDispatched + 1,
                  });
                })
                .catch(() => {
                  that.setState({
                    partitionsDispatched: that.state.partitionsDispatched + 1,
                  });
                });
            },
            index * 2000,
            this,
            notification,
            partition,
          );
        });
      }
    });
  }

  previewNotification(e) {
    if (!e) return;
    HelperNotification.preview({
      title: this.state.title,
      body: this.state.content ? this.state.content : this.state.title,
      icon: this.state.product.applications[0].icon,
      image: this.state.image,
    });
  }

  render() {
    const { user } = this.props.auth;

    if (user.role === 'VIEWER') {
      return <Redirect to="/" />;
    }

    if (!this.state.product) {
      return <Loading />;
    }

    if (this.state.wait) {
      if (
        this.state.partitionsToDispatch > 0 &&
        this.state.partitionsDispatched === this.state.partitionsToDispatch
      ) {
        window.location = this.state.redirectTo;
      }
      return (
        <Page.Content className="mt-0">
          <Grid.Row cards={true}>
            <Grid.Col offset={3} width={6}>
              <Page.Header title={this.state.product.name} />
              {this.state.partitionsToDispatch ? (
                <ProgressCard
                  header="Dispatching notifications..."
                  content={
                    Math.round(
                      (this.state.partitionsDispatched /
                        this.state.partitionsToDispatch) *
                        100,
                    ) + '%'
                  }
                  progressColor="green"
                  progressWidth={
                    (this.state.partitionsDispatched /
                      this.state.partitionsToDispatch) *
                    100
                  }
                />
              ) : (
                <Loading />
              )}
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      );
    }

    let platformDesktop, platformMobile, submitButton;
    if (this.state.applicationTypes.indexOf(0) > -1) {
      let platformDesktopURL;
      if (this.state.toggleDesktop) {
        platformDesktopURL = (
          <Form.FieldSet>
            <Form.Input
              label="Launch URL"
              name="urlDesktop"
              placeholder="Desktop Website Launch URL"
              onBlur={this.onPlatformURLBlur.bind(this)}
              onChange={this.onPlatformURLChange.bind(this)}
            />
            <Form.Input
              label="Action Text"
              onChange={e =>
                e && this.setState({ actionDesktop: e.target.value })
              }
              placeholder="Desktop Website Action Text"
            />
          </Form.FieldSet>
        );
      }
      platformDesktop = (
        <React.Fragment>
          <Form.Switch
            type="checkbox"
            onChange={e =>
              e &&
              this.setState(prevState => ({
                toggleDesktop: !prevState.toggleDesktop,
                urlDesktop: !prevState.toggleDesktop
                  ? prevState.urlDesktop
                  : '',
              }))
            }
            label="Desktop Website"
          />
          {platformDesktopURL}
        </React.Fragment>
      );
    }
    if (this.state.applicationTypes.indexOf(1) > -1) {
      let platformMobileURL;
      if (this.state.toggleMobile) {
        platformMobileURL = (
          <Form.FieldSet>
            <Form.Input
              label="Launch URL"
              name="urlMobile"
              placeholder="Mobile Website Launch URL"
              onBlur={this.onPlatformURLBlur.bind(this)}
              onChange={this.onPlatformURLChange.bind(this)}
            />
            <Form.Input
              label="Action Text"
              onChange={e =>
                e && this.setState({ actionDesktop: e.target.value })
              }
              placeholder="Mobile Website Action Text"
            />
          </Form.FieldSet>
        );
      }
      platformMobile = (
        <React.Fragment>
          <Form.Switch
            type="checkbox"
            onChange={e =>
              e &&
              this.setState(prevState => ({
                toggleMobile: !prevState.toggleMobile,
                urlMobile: !prevState.toggleMobile ? prevState.urlMobile : '',
              }))
            }
            label="Mobile Website"
          />
          {platformMobileURL}
        </React.Fragment>
      );
    }
    if (this.state.toggleSchedule) {
      submitButton = (
        <Button
          loading={this.state.wait}
          color="warning"
          icon="clock"
          onClick={this.onSubmit.bind(this)}
          disabled={
            this.state.wait ||
            !(
              this.state.title.length &&
              this.state.content.length &&
              (this.state.urlDesktop.length || this.state.urlMobile.length)
            )
          }
        >
          Schedule
        </Button>
      );
    } else {
      submitButton = (
        <Button
          loading={this.state.wait}
          color="primary"
          icon="send"
          onClick={this.onSubmit.bind(this)}
          disabled={
            this.state.wait ||
            !(
              this.state.title.length &&
              this.state.content.length &&
              (this.state.urlDesktop.length || this.state.urlMobile.length)
            )
          }
        >
          Send
        </Button>
      );
    }
    return (
      <Page.Content className="mt-0">
        <Grid.Row cards={true}>
          <Grid.Col offset={3} width={6}>
            <Page.Header title={this.state.product.name} />
            <Card>
              <Card.Header>
                <Card.Title>New Message</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group
                    label={
                      <Form.Label
                        aside={this.state.title.length + ' characters'}
                        children="Title"
                      />
                    }
                  >
                    <Form.Input
                      placeholder="Notification Title"
                      onChange={e =>
                        e && this.setState({ title: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    label={
                      <Form.Label
                        aside={this.state.content.length + ' characters'}
                        children="Content"
                      />
                    }
                  >
                    <Form.Textarea
                      placeholder="Notification Content"
                      onChange={e =>
                        e && this.setState({ content: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group label="Platforms">
                    <Form.SwitchStack>
                      {platformDesktop}
                      {platformMobile}
                    </Form.SwitchStack>
                  </Form.Group>
                  <Form.Group label="Image">
                    <Form.FileInput
                      name="upload"
                      onChange={this.onFileChange.bind(this)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.ImageCheck>
                      {Object.keys(this.state.images).map(key => (
                        <Grid.Col key={key} width={4}>
                          <label className="imagecheck mb-4">
                            <input
                              name="image"
                              type="radio"
                              checked={this.state.images[key].selected}
                              value={this.state.images[key].url}
                              className="imagecheck-input"
                              onChange={this.onImageSelect.bind(this)}
                            />
                            <figure className="imagecheck-figure">
                              <img
                                src={this.state.images[key].url}
                                alt="Select"
                                className="imagecheck-image"
                              />
                            </figure>
                          </label>
                        </Grid.Col>
                      ))}
                    </Form.ImageCheck>
                  </Form.Group>
                  <Form.Group label="Schedule">
                    <Form.Switch
                      type="checkbox"
                      onChange={e =>
                        e &&
                        this.setState(prevState => ({
                          toggleSchedule: !prevState.toggleSchedule,
                          scheduleDate: !prevState.toggleSchedule
                            ? prevState.scheduleDate
                            : '',
                        }))
                      }
                      label="Send at a future date and time"
                    />
                    {this.state.toggleSchedule && (
                      <Form.MaskedInput
                        placeholder="0000-00-00 00:00"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          ' ',
                          /\d/,
                          /\d/,
                          ':',
                          /\d/,
                          /\d/,
                        ]}
                        onChange={e =>
                          e && this.setState({ scheduleDate: e.target.value })
                        }
                      />
                    )}
                  </Form.Group>
                  <Form.Group label="Delivery Method">
                    <Form.SwitchStack>
                      <Form.Switch
                        label="Full Rollout"
                        name="deliveryMethod"
                        type="radio"
                        onChange={e =>
                          e &&
                          this.setState({
                            deliveryMethod: e.target.value,
                            partitionGroup: HelperNotification.getPartitionGroup(
                              'all',
                            ),
                          })
                        }
                        checked={this.state.deliveryMethod === 'all'}
                        value="all"
                      />
                      <Form.Switch
                        label="Canary"
                        name="deliveryMethod"
                        type="radio"
                        onChange={e =>
                          e &&
                          this.setState({
                            deliveryMethod: e.target.value,
                            partitionGroup: HelperNotification.getPartitionGroup(
                              'canary',
                            ),
                          })
                        }
                        checked={this.state.deliveryMethod === 'canary'}
                        value="canary"
                      />
                      <Form.Switch
                        label="Multivariate Testing"
                        name="deliveryMethod"
                        type="radio"
                        onChange={e =>
                          e &&
                          this.setState({
                            deliveryMethod: e.target.value,
                            multivariateGroup: 'a',
                            partitionGroup: HelperNotification.getPartitionGroup(
                              'multivariate',
                            ).a,
                          })
                        }
                        checked={this.state.deliveryMethod === 'multivariate'}
                        value="multivariate"
                      />
                    </Form.SwitchStack>
                  </Form.Group>
                  {this.state.deliveryMethod === 'multivariate' && (
                    <Form.Group label="Test Group">
                      <Form.SelectGroup className="w-5">
                        <Form.SelectGroupItem
                          label="A"
                          name="partitionGroup"
                          onChange={e =>
                            e &&
                            this.setState({
                              multivariateGroup: 'a',
                              partitionGroup: HelperNotification.getPartitionGroup(
                                'multivariate',
                              )[e.target.value],
                            })
                          }
                          checked={this.state.multivariateGroup === 'a'}
                          value="a"
                        />
                        <Form.SelectGroupItem
                          label="B"
                          name="partitionGroup"
                          onChange={e =>
                            e &&
                            this.setState({
                              multivariateGroup: 'b',
                              partitionGroup: HelperNotification.getPartitionGroup(
                                'multivariate',
                              )[e.target.value],
                            })
                          }
                          checked={this.state.multivariateGroup === 'b'}
                          value="b"
                        />
                      </Form.SelectGroup>
                    </Form.Group>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer>
                <Button.List align="right">
                  <Button
                    color="secondary"
                    icon="eye"
                    disabled={
                      this.state.wait ||
                      (!this.state.title.length &&
                        !this.state.content.length &&
                        !this.state.urlDesktop.length &&
                        !this.state.urlMobile.length)
                    }
                    onClick={this.previewNotification.bind(this)}
                  >
                    Preview
                  </Button>
                  {submitButton}
                </Button.List>
              </Card.Footer>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ComposePage);
