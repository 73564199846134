import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
};

function TablePagination({ total, current, onPageChange }) {
  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <div style={styles.container}>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={total}
        color="primary"
        // showFirstButton
        // showLastButton
        onChange={handlePageChange}
      />
    </div>
  );
}

export default TablePagination;
