import {
  CognitoAuth,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
} from 'amazon-cognito-auth-js';
import axios from 'axios';

import APIManager from '../managers/APIManager';

class AuthManager {
  cognito = null;

  constructor() {
    let authData = {
      ClientId: '58jjm9ukbc06idmfpa5hrqk1co',
      AppWebDomain: 'auth.empower.net',
      TokenScopesArray: ['phone', 'email', 'openid', 'profile'],
      RedirectUriSignIn: 'https://comet.empower.net/auth/login',
      RedirectUriSignOut: 'https://comet.empower.net/auth/logout',
      UserPoolId: 'eu-west-1_dC5lernRF',
      AdvancedSecurityDataCollectionFlag: false,
    };

    this.cognito = new CognitoAuth(authData);
    this.cognito.userhandler = {
      onFailure: () => {},
      onSuccess: () => {},
    };
    this.cognito.useCodeGrantFlow();

    return this;
  }

  login(code) {
    return APIManager.getToken(code).then(res => {
      return this.updateTokens(res).then(() => {
        return APIManager.getProfile().then(res => res.data);
      });
    });
  }

  logout() {
    localStorage.removeItem('refreshToken');
    this.cognito.clearCachedTokensScopes();
  }

  redirectForLogin() {
    this.cognito.getSession();
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      window.location.href = '/auth/logout';
      return;
    }
    return APIManager.refreshToken(refreshToken).then(res => {
      return this.updateTokens(res);
    });
  }

  setAuthorizationHeaders(token) {
    token = token || localStorage.getItem('accessToken');
    if (token) {
      localStorage.setItem('accessToken', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }

  updateTokens(res) {
    return new Promise(resolve => {
      if (res.data.id_token) {
        this.cognito.signInUserSession.setIdToken(
          new CognitoIdToken(res.data.id_token),
        );
      }
      if (res.data.access_token) {
        this.cognito.signInUserSession.setAccessToken(
          new CognitoAccessToken(res.data.access_token),
        );
      }
      if (res.data.refresh_token) {
        this.cognito.signInUserSession.setRefreshToken(
          new CognitoRefreshToken(res.data.refresh_token),
        );
        localStorage.setItem('refreshToken', res.data.refresh_token);
      }
      this.cognito.cacheTokensScopes();

      const token = this.cognito.signInUserSession.getIdToken().getJwtToken();
      this.setAuthorizationHeaders(token);

      return resolve(token);
    });
  }
}

export default AuthManager;
