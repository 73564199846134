import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Icon,
  Page,
  Table,
} from 'tabler-react';

import Loading from '../components/Loading';
import APIManager from '../managers/APIManager';

class DashboardPage extends React.Component {
  state = {
    products: [],
  };

  componentDidMount() {
    APIManager.getProducts('288ba467-ebf5-4b7c-9b5c-38a0c4c62df8').then(res => {
      let products = res.data.products.map(_product => {
        let statsActive = 0,
          statsUsers = 0;
        _product.applications.forEach(
          _application => {
            statsActive += _application.statsActive;
            statsUsers += _application.statsUsers;
          },
        );
        _product.statsActive = statsActive;
        _product.statsUsers = statsUsers;
        return _product;
      });
      this.setState({ products });
    });
  }

  render() {
    const { user } = this.props.auth;

    return this.state.products.length ? (
      <Page.Content title="Dashboard">
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card title="Products">
              <Table
                responsive
                className="card-table table-vcenter text-nowrap"
                headerItems={[
                  { content: 'Product' },
                  { content: 'Subscribers' },
                  { content: 'Total Users' },
                  { content: 'Platforms', className: 'w-10' },
                  { content: 'Actions', className: 'w-5' },
                ]}
                bodyItems={this.state.products.map(_product => ({
                  key: _product.id,
                  item: [
                    {
                      content: (
                        <Link to={'/products/' + _product.id + '/messages'}>
                          {_product.name}
                        </Link>
                      ),
                    },
                    {
                      content: (
                        <React.Fragment>
                          {_product.statsActive.toLocaleString(undefined, 2)}
                        </React.Fragment>
                      ),
                    },
                    {
                      content: (
                        <React.Fragment>
                          {_product.statsUsers.toLocaleString(undefined, 2)}
                        </React.Fragment>
                      ),
                    },
                    {
                      content: (
                        <React.Fragment>
                          <Icon prefix="fa" name="chrome" />{' '}
                          <Icon prefix="fa" name="edge" />{' '}
                          <Icon prefix="fa" name="firefox" />
                        </React.Fragment>
                      ),
                    },
                    {
                      alignContent: 'right',
                      content: (
                        <Button.List>
                          {user.role !== 'VIEWER' &&
                            <Button
                              RootComponent="a"
                              href={'/products/' + _product.id + '/compose'}
                              size="sm"
                              color="secondary"
                            >
                              New Message
                            </Button>
                          }
                          {user.role === 'OWNER' &&
                            <Button
                              RootComponent="a"
                              href={'/products/' + _product.id + '/settings'}
                              size="sm"
                              color="secondary"
                            >
                              Settings
                            </Button>
                          }
                        </Button.List>
                      ),
                    },
                  ],
                }))}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    ) : (
      <Loading />
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(DashboardPage);
