const time = {
  ONE_DAY: 1000 * 60 * 60 * 24,
  ONE_WEEK: 1000 * 60 * 60 * 24 * 7,
  TWO_WEEKS: 1000 * 60 * 60 * 24 * 14,
  SIX_HOURS: 1000 * 60 * 60 * 6,

  timeSince: ts => {
    if (typeof ts === 'string') {
      ts = new Date(ts).getTime();
    }
    let now = new Date().getTime();
    return now - ts;
  },

  toFormattedString: date => {
    return (
      date.getDate() +
      '.0' +
      (date.getMonth() + 1) +
      '.' +
      date.getFullYear() +
      '_' +
      date.getHours() +
      ':' +
      date.getMinutes()
    );
  },
};

export default time;
