import HelperTime from './time';
import moment from 'moment-timezone';

const message = {
  getDeliveryMethod: message => {
    if (message.notifications[0].partitionsTargeted.length === 1) {
      return {
        method: 'canary',
        color: 'warning',
      };
    } else if (
      message.notifications[0].partitionsTargeted.length === 8 &&
      message.notifications[0].partitionsTargeted.indexOf('0') === 0
    ) {
      return {
        method: 'a',
        color: 'primary',
      };
    } else if (
      message.notifications[0].partitionsTargeted.length === 8 &&
      message.notifications[0].partitionsTargeted.indexOf('8') === 0
    ) {
      return {
        method: 'b',
        color: 'danger',
      };
    }
    return {
      method: 'all',
      color: 'secondary',
    };
  },
  getDeliveryMethodAlert: message => {
    let alert;
    if (
      message.notifications[0].partitionsTargeted.length === 1 &&
      message.notifications[0].partitionsTargeted.length >=
        message.notifications[0].partitionsSent.length
    ) {
      alert = {
        method: 'canary',
        type: 'warning',
        icon: 'activity',
        title: 'This is a canary delivery!',
        text:
          "You may proceed with a full rollout once you're convinced this notification was a success.",
      };
    } else if (
      message.notifications[0].partitionsTargeted.length === 8 &&
      message.notifications[0].partitionsTargeted.indexOf('0') === 0
    ) {
      alert = {
        method: 'multivariate',
        type: 'info',
        icon: 'sliders',
        title: 'This is a multivariate delivery!',
        text: 'You sent this notification to the Test Group A.',
      };
    } else if (
      message.notifications[0].partitionsTargeted.length === 8 &&
      message.notifications[0].partitionsTargeted.indexOf('8') === 0
    ) {
      alert = {
        method: 'multivariate',
        type: 'info',
        icon: 'sliders',
        title: 'This is a multivariate delivery!',
        text: 'You sent this notification to the Test Group B.',
      };
    }
    return alert;
  },
  getStatisticsAlert: message => {
    let timeSinceCreated = HelperTime.timeSince(message.createdAt);
    let alert;
    if (timeSinceCreated > HelperTime.ONE_WEEK) {
      alert = {
        type: 'info',
        icon: 'lock',
        title: 'Statistics are frozen!',
        text: 'Statistics of this notification are now final.',
      };
    } else if (timeSinceCreated > HelperTime.ONE_DAY) {
      alert = {
        type: 'success',
        icon: 'check',
        title: 'Delivery is completed!',
        text:
          'Browsers are mostly done delivering this message and upcoming clicks are expected to be low in volume.',
      };
    } else {
      alert = {
        type: 'warning',
        icon: 'clock',
        title: message.scheduleAt && new Date(message.scheduleAt).getTime()>new Date().getTime()?
           'Delivery is scheduled! on '+ moment(message.scheduleAt.replace('.000Z', '-00:00'),).format('lll'): 'Delivery in progress!',
        text: message.scheduleAt && new Date(message.scheduleAt).getTime()>new Date().getTime()?
          'User will be received the notification after time scheduled'
          :'Users are now receiving the notification as they open their browsers...',
      };
    }
    return alert;
  },
};

export default message;
