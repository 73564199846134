import * as React from 'react';
import PropTypes from 'prop-types';
import { FormCard, Grid, Page, Text } from 'tabler-react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import logo from './../assets/images/comet-logo.png';

import HelperURL from '../helpers/url';
import AuthManager from '../managers/AuthManager';
import { AuthActionCreators } from '../store/actions/auth';

class AuthPage extends React.Component {
  state = {
    auth: null
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    let auth = new AuthManager();
    this.setState({ auth });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.state.auth.redirectForLogin();
  }

  render() {
    if (this.props.match.params.action === 'logout') {
      this.state.auth.logout();
      this.props.logout();
      return <Redirect to='/auth/login' />;
    } else if (this.props.match.params.action === 'login' && window.location.search.length > 1) {
      let code = HelperURL.getQueryParameter('code');
      this.state.auth.login(code).then(user => {
        this.props.login(user);
        this.props.history.push('/');
      });
      return null;
    } else {
      return (
        <Page>
          <Grid.Row>
            <Grid.Col className="col-login mx-auto">
              <Text align="center" className="mb-4">
                <img src={logo} className="h-8" alt="Comet Logo" />
              </Text>
              <FormCard
                buttonText="Login"
                title="Please login to proceed..."
                onSubmit={this.handleSubmit}
              />
            </Grid.Col>
          </Grid.Row>
        </Page>
      );
    }
  }
}

AuthPage.propTypes = {
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default withRouter(connect(null, AuthActionCreators)(AuthPage));