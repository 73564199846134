import axios from 'axios';

import AuthManager from './AuthManager';

const API_BASE = process.env.REACT_APP_API_BASE;
const API_KEY = process.env.REACT_APP_API_KEY;

const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const AUTH_CREDENTIALS = process.env.REACT_APP_AUTH_CREDENTIALS;
const AUTH_REDIRECT_URI = process.env.REACT_APP_AUTH_REDIRECT_URI;

axios.defaults.baseURL = API_BASE;
axios.defaults.headers.common['x-api-key'] = API_KEY;

axios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.status === 401) {
      return new AuthManager().refreshToken().then(token => {
        if (!token) {
          window.location.href = '/auth/logout';
          return;
        }
        err.config.headers['Authorization'] = `Bearer ${token}`;
        return axios.request(err.config);
      });
    }
    return Promise.reject(err);
  },
);

const APIManager = {
  getToken: code => {
    let data = `grant_type=authorization_code&client_id=${AUTH_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URI}&code=${code}`;
    return axios.post('https://auth.empower.net/oauth2/token', data, {
      headers: {
        Authorization: `Basic ${AUTH_CREDENTIALS}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  refreshToken: refreshToken => {
    let data = `grant_type=refresh_token&client_id=${AUTH_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URI}&refresh_token=${refreshToken}`;
    return axios.post('https://auth.empower.net/oauth2/token', data, {
      headers: {
        Authorization: `Basic ${AUTH_CREDENTIALS}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  getProfile: () => {
    return axios.get(`/auth/profile`).then(res => res.data);
  },

  getApplication: application => {
    return axios.get(`/applications/${application}`).then(res => res.data);
  },

  getOrganization: organization => {
    return axios.get(`/organizations/${organization}`).then(res => res.data);
  },

  getProducts: organization => {
    return axios
      .get(`/organizations/${organization}/products`)
      .then(res => res.data);
  },

  getProduct: product => {
    return axios.get(`/products/${product}`).then(res => res.data);
  },

  getProductApplications: product => {
    return axios.get(`/products/${product}/applications`).then(res => res.data);
  },

  getMessages: (product, page, perPage) => {
    const q =
      page <= 0
        ? `/products/${product}/messages?perPage=${perPage}`
        : `/products/${product}/messages?page=${page}&perPage=${perPage}`;
    return axios.get(q).then(res => res.data);
  },

  getMessageCount: product => {
    return axios
      .get(`/products/${product}/messages/count`)
      .then(res => res.data);
  },

  getMessage: message => {
    return axios.get(`/messages/${message}`).then(res => res.data);
  },

  getNotification: notification => {
    return axios.get(`/notifications/${notification}`).then(res => res.data);
  },

  getNotifications: message => {
    return axios
      .get(`/messages/${message}/notifications`)
      .then(res => res.data);
  },

  getNotificationStats: params => {
    return axios
      .get(
        `/notifications/stats?ids=${params.ids.join(',')}&g=${
          params.granularity
        }&start=${params.start}&end=${params.end}&access_token=${API_KEY}`,
        {
          baseURL: 'https://api.empower.net/v0',
        },
      )
      .then(res => res.data);
  },

  createMessage: message => {
    return axios.post(`/messages`, message).then(res => res.data);
  },

  getServiceStatus: () => {
    return axios.get(`/services/status`).then(res => res.data);
  },

  dispatchNotification: (notificationId, partitionGroup) => {
    return axios
      .post(`/services/dispatch`, {
        notificationId,
        partitionGroup,
      })
      .then(res => res.data);
  },

  cancelMessage: messageId => {
    return axios.delete(`/messages/cancel/${messageId}`).then(res => res.data);
  },

  updateMessage: (messageId, message) => {
    return axios
      .patch(`/messages/${messageId}`, { ...message })
      .then(res => res.data);
  },
};

export default APIManager;
