import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  Grid,
  List,
  Page,
  Table,
  Text,
} from 'tabler-react';

import Loading from '../components/Loading';
import APIManager from '../managers/APIManager';
import usePagination from '../hooks/usePagination';
import TablePagination from '../components/TablePagination';
import { IconButton, Snackbar, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import SnackbarNotification from '../components/SnackbarNotification';
import EditModal from '../components/EditModal';

const MessagesPage = props => {
  const [messageData, setMessageData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(1);
  const [ope, setOpe] = useState(0);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleOpenDeleteModal = message => {
    setItemToDelete({ ...message });
    setOpe(0);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setItemToDelete(null);
  };

  const handleOpenEditModal = message => {
    setItemToDelete({ ...message });
    setOpe(1);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteItem = () => {
    APIManager.cancelMessage(itemToDelete.id)
      .then(res => {
        handleCloseDeleteModal();
        setRefresh(prev => !prev);
        if (res.errors.length > 0) {
          setSnackbarMessage(0);
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(1);
          setOpenSnackbar(true);
        }
      })
      .catch(err => console.error(err));
  };

  const handleEditItem = () => {};

  useEffect(
    () => {
      APIManager.getMessageCount(props.match.params.product).then(res => {
        setMessageData({ ...res.data });
      });
    },
    [props.match.params],
  );

  const {
    currentPage,
    mes,
    productData,
    loading,
    error,
    paginate,
    setRefresh,
  } = usePagination(props.match.params.product, 1, 7);

  const { user } = props.auth;

  let composeButton, settingsButton;
  if (productData && user.role !== 'VIEWER') {
    composeButton = (
      <div className="mb-5">
        <Button
          RootComponent="a"
          href={`/products/${productData.id}/compose`}
          block={true}
          icon="send"
          color="primary"
        >
          Compose New Message
        </Button>
      </div>
    );
  }

  // if (productData && user.role === 'OWNER') {
  //   settingsButton = (
  //     <List.GroupItem
  //       to={`/products/${productData.id}/settings`}
  //       className="d-flex align-items-center"
  //       icon="settings"
  //     >
  //       Settings
  //       <Badge className="ml-auto badge badge-secondary">soon</Badge>
  //     </List.GroupItem>
  //   );
  // }

  const pageContent = productData ? (
    <Page.Content

    // sidebar={
    //   <React.Fragment>
    //     {composeButton}
    //     <List.Group transparent={true}>
    //       <List.GroupItem
    //         className="d-flex align-items-center"
    //         to={'/products/' + productData.id + '/messages'}
    //         icon="mail"
    //         active
    //       >
    //         Messages
    //       </List.GroupItem>
    //       <List.GroupItem
    //         to="#"
    //         className="d-flex align-items-center"
    //         icon="users"
    //       >
    //         Audiences
    //         <Badge className="ml-auto badge badge-secondary">soon</Badge>
    //       </List.GroupItem>
    //       <List.GroupItem
    //         to="#"
    //         className="d-flex align-items-center"
    //         icon="user"
    //       >
    //         Subscribers
    //         <Badge className="ml-auto badge badge-secondary">soon</Badge>
    //       </List.GroupItem>
    //       {settingsButton}
    //     </List.Group>
    //   </React.Fragment>
    // }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>{productData.name}</h3>
        <div>{composeButton}</div>
      </div>

      <Grid.Row cards={true}>
        <Grid.Col width={16}>
          <Card title="Messages">
            {loading ? (
              <Loading />
            ) : (
              <Table
                responsive
                className="card-table table-vcenter text-nowrap"
                headerItems={[
                  { content: 'Title' },
                  { content: 'Delivery' },
                  { content: 'Interaction' },
                  { content: 'CTR' },
                  { content: 'Status' },
                  { content: 'Actions' },
                ]}
                bodyItems={mes.map(_message => {
                  const curDate = new Date();
                  const scheduleDate = new Date(_message.scheduleAt);
                  return {
                    key: _message.id,
                    item: [
                      {
                        content: (
                          <React.Fragment>
                            <Link
                              to={
                                '/products/' +
                                props.match.params.product +
                                '/messages/' +
                                _message.id
                              }
                            >
                              {_message.title}
                            </Link>
                            <Text size="sm" muted>
                              {moment(
                                _message.createdAt.replace('.000Z', '-00:00'),
                              ).format('lll')}
                            </Text>
                          </React.Fragment>
                        ),
                      },
                      {
                        content: (
                          <React.Fragment>
                            <div>
                              {_message.statsReceived.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              received
                            </div>
                            <Text size="sm" muted>
                              {_message.statsSent.toLocaleString(undefined, 2)}{' '}
                              sent
                            </Text>
                          </React.Fragment>
                        ),
                      },
                      {
                        content: (
                          <React.Fragment>
                            <div>
                              {_message.statsClicked.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              clicked
                            </div>
                            <Text size="sm" muted>
                              {_message.statsDismissed.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              dismissed
                            </Text>
                          </React.Fragment>
                        ),
                      },
                      {
                        content: (
                          <React.Fragment>
                            {Math.round(
                              (_message.statsClicked / _message.statsReceived) *
                                10000,
                            ) / 100}
                            %
                          </React.Fragment>
                        ),
                      },
                      {
                        content:
                          _message.status === 'Sent' ? (
                            <React.Fragment>
                              <span className="status-icon bg-success" /> Sent
                              {_message.deliveryMethod.method !== 'all' && (
                                <React.Fragment>
                                  <br />
                                  <Badge
                                    className={`ml-auto w-100 badge badge-${
                                      _message.deliveryMethod.color
                                    }`}
                                  >
                                    {_message.deliveryMethod.method.toUpperCase()}
                                  </Badge>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span className="status-icon bg-warning" />{' '}
                              Sending
                              {_message.deliveryMethod.method !== 'all' && (
                                <React.Fragment>
                                  <br />
                                  <Badge
                                    className={`ml-auto w-100 badge badge-${
                                      _message.deliveryMethod.color
                                    }`}
                                  >
                                    {_message.deliveryMethod.method.toUpperCase()}
                                  </Badge>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ),
                      },
                      {
                        content: (
                          <div>
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => handleOpenEditModal(_message)}
                                disabled={curDate > scheduleDate}
                              >
                                <ModeIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => handleOpenDeleteModal(_message)}
                                disabled={curDate > scheduleDate}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ),
                      },
                    ],
                  };
                })}
              />
            )}
            <TablePagination
              total={
                messageData && messageData.messageCount
                  ? Math.ceil(messageData.messageCount / 7)
                  : 0
              }
              current={2}
              onPageChange={paginate}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  ) : (
    <Loading />
  );

  return (
    <>
      {pageContent}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onRequestClose={handleCloseDeleteModal}
        onDelete={handleDeleteItem}
        item={itemToDelete}
      />
      <EditModal
        isOpen={isEditModalOpen}
        onRequestClose={handleCloseEditModal}
        onSave={handleEditItem}
        item={itemToDelete}
        setOpenSnackbar={setOpenSnackbar}
        setOpe={setOpe}
        setSnackbarMessage={setSnackbarMessage}
      />

      <SnackbarNotification
        open={openSnackbar}
        handleClose={handleClose}
        message={snackbarMessage}
        ope={ope}
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(MessagesPage));
