import * as React from 'react';
import { Planet } from 'react-kawaii';
import { Error403Page, Grid, Page } from 'tabler-react';

export default class DocumentationPage extends React.Component {
  render() {
    return (
      <Page.Content title="Documentation">
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Error403Page details={<Planet size={200} mood="excited" color="#E0E5EC" />} title="Coming Soon" subtitle="We're still working on this, please visit later!" />
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    );
  }
}
