import moment from 'moment-timezone';
import * as React from 'react';
import C3Chart from 'react-c3js';
import {
  Alert,
  Button,
  Card,
  colors,
  Dimmer,
  Grid,
  Icon,
  Page,
  StampCard,
  Table,
  Text,
} from 'tabler-react';
import Loading from '../components/Loading';
import HelperMessage from '../helpers/message';
import HelperNotification from '../helpers/notification';
import HelperTime from '../helpers/time';
import APIManager from '../managers/APIManager';

export default class MessagePage extends React.Component {
  state = {
    chart: {
      data: {
        x: 'x',
        columns: [],
        type: 'area-spline',
        groups: [['data1', 'data2', 'data3', 'data4']],
        colors: {
          data1: colors['blue'],
          data2: colors['pink'],
          data3: colors['green'],
          data4: colors['grey'],
        },
        names: {
          data1: 'Desktop Website',
          data2: 'Mobile Website',
          data3: 'Android Application',
          data4: 'iOS Application',
        },
        order: null,
      },
      axis: {
        y: {
          show: false,
        },
        x: {
          show: false,
          type: 'category',
        },
      },
    },
    canaryLoading: false,
    message: null,
    stats: null,
  };

  completeCanaryDelivery(e) {
    if (!e) return;
    e.preventDefault();
    this.setState({ canaryLoading: true });
    this.state.message.notifications.forEach(notification => {
      APIManager.dispatchNotification(
        notification.id,
        HelperNotification.getPartitionGroup('all')
          .join('')
          .replace(this.state.message.notifications[0].partitionsTargeted, ''),
      ).then(() => {
        window.location.reload();
      });
    });
  }

  fetchChartData() {
    let notificationTypeMap = {};
    let notificationIds = [];
    this.state.message.notifications.forEach(notification => {
      notificationIds.push(notification.id);
      notificationTypeMap[notification.id] = notification.application.type;
    });
    let start = new Date(this.state.message.scheduleAt?this.state.message.scheduleAt:this.state.message.createdAt);
    let end = new Date().getTime()<start.getTime()?start:new Date();
    let startDate = HelperTime.toFormattedString(start);
    let endDate = HelperTime.toFormattedString(end);

    let granularity = 'minutely';
    if (
      HelperTime.timeSince(this.state.message.createdAt) > HelperTime.SIX_HOURS
    ) {
      granularity = 'hourly';
    }

    APIManager.getNotificationStats({
      granularity: granularity,
      ids: notificationIds,
      start: startDate,
      end: endDate,
    }).then(res => {
      let chart = this.state.chart;
      let timeSeries = [];
      chart.data.columns = [];
      for (let notificationId in res.data) {
        let dataSet = [];
        let applicationType = HelperNotification.getApplicationType(
          notificationTypeMap[notificationId],
        );
        dataSet = [
          applicationType.item,
          ...Object.values(res.data[notificationId]),
        ];
        chart.data.columns.push(dataSet);
        timeSeries = ['x', ...Object.keys(res.data[notificationId])];
      }
      chart.data.columns.unshift(timeSeries);
      this.setState({ chart });
    });
  }

  fetchMessageData() {
    return APIManager.getNotifications(this.props.match.params.message).then(
      res => {
        let message = res.data;
        let stats = {
          clicked: 0,
          ctr: 0,
          received: 0,
          sent: 0,
        };
        message.status = 'Sent';
        message.notifications.forEach((notification, i) => {
          stats.clicked += notification.statsClicked;
          stats.received += notification.statsReceived;
          stats.sent += notification.statsSent;
          stats.ctr =
            Math.round((stats.clicked / stats.received) * 10000) / 100;
          if (
            notification.partitionsTargeted.length >
            notification.partitionsSent.length
          ) {
            message.status = 'Sending';
          }
        });
        this.setState({ message, stats });
      },
    );
  }

  previewNotification(e) {
    if (!e) return;
    HelperNotification.preview(this.state.message.notifications[0].payload);
  }

  refreshData() {
    this.fetchMessageData();
    this.fetchChartData();
  }

  componentDidMount() {
    this.fetchMessageData().then(() => {
      this.fetchChartData();
      if (
        new Date().getTime() -
          new Date(this.state.message.createdAt).getTime() <
        1000 * 60 * 60 * 24
      ) {
        setInterval(this.refreshData.bind(this), 10000, this);
      }
    });
  }

  render() {
    if (!this.state.message) {
      return <Loading />;
    }
    let alertDeliveryMethod,
      alertStatistics,
      deliveryChart,
      notificationDetails;
    let _alertDeliveryMethod = HelperMessage.getDeliveryMethodAlert(
      this.state.message,
    );
    if (_alertDeliveryMethod) {
      alertDeliveryMethod = (
        <Alert type={_alertDeliveryMethod.type}>
          {_alertDeliveryMethod.method === 'canary' ? (
            <React.Fragment>
              <p
                style={{
                  float: 'left',
                  'line-height': '38px',
                  margin: 0,
                }}
              >
                <Icon className="mr-3" name={_alertDeliveryMethod.icon} />
                <strong>{_alertDeliveryMethod.title}</strong>{' '}
                {_alertDeliveryMethod.text}
              </p>
              <Button
                color="success"
                RootComponent="button"
                icon="check"
                className="float-right"
                onClick={this.completeCanaryDelivery.bind(this)}
                loading={this.state.canaryLoading}
                disabled={this.state.canaryLoading}
              >
                Proceed
              </Button>
              <div style={{ clear: 'both' }} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Icon className="mr-3" name={_alertDeliveryMethod.icon} />
              <strong>{_alertDeliveryMethod.title}</strong>{' '}
              {_alertDeliveryMethod.text}
            </React.Fragment>
          )}
        </Alert>
      );
    }
    let _alertData = HelperMessage.getStatisticsAlert(this.state.message);
    if (_alertData) {
      alertStatistics = (
        <Alert icon={_alertData.icon} type={_alertData.type}>
          <strong>{_alertData.title}</strong> {_alertData.text}
        </Alert>
      );
    }
    let timeSinceCreated = HelperTime.timeSince(this.state.message.createdAt);
    if (timeSinceCreated < HelperTime.TWO_WEEKS) {
      if (
        !this.state.chart.data.columns.length ||
        this.state.chart.data.columns[0].length === 1
      ) {
        deliveryChart = (
          <Card.Body>
            <Dimmer active loader>
              &nbsp;
            </Dimmer>
          </Card.Body>
        );
      } else {
        deliveryChart = (
          <Card.Body>
            <C3Chart
              data={this.state.chart.data}
              axis={this.state.chart.axis}
              legend={{
                show: false,
              }}
              padding={{
                bottom: 0,
                top: 0,
              }}
            />
          </Card.Body>
        );
      }
    }
    notificationDetails = this.state.message.notifications.map(
      (notification, i) => {
        let actionButtons = notification.payload.actions.map((action, j) => ({
          key: 'actions' + j,
          item: [
            {
              content: 'Action Button ' + (j + 1),
            },
            {
              content: (
                <a
                  href={notification.payload.actions[j].url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {notification.payload.actions[j].title}
                </a>
              ),
            },
          ],
        }));
        return (
          <Grid.Row cards={true}>
            <Grid.Col width={12}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    {
                      HelperNotification.getApplicationType(
                        notification.application.type,
                      ).label
                    }
                  </Card.Title>
                  <Card.Options>
                    <Button
                      loading={this.state.wait}
                      color="primary"
                      icon="eye"
                      size="sm"
                      onClick={this.previewNotification.bind(this)}
                    >
                      Preview
                    </Button>
                  </Card.Options>
                </Card.Header>
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[{ content: 'Property' }, { content: 'Value' }]}
                  bodyItems={[
                    {
                      key: 'title',
                      item: [
                        {
                          content: 'Title',
                        },
                        {
                          content: notification.payload.title,
                        },
                      ],
                    },
                    {
                      key: 'body',
                      item: [
                        {
                          content: 'Body',
                        },
                        {
                          content: notification.payload.body,
                        },
                      ],
                    },
                    {
                      key: 'icon',
                      item: [
                        {
                          content: 'Icon',
                        },
                        {
                          content: (
                            <a
                              href={notification.payload.icon}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={notification.payload.icon}
                                alt="Notification Icon"
                                height="100"
                              />
                            </a>
                          ),
                        },
                      ],
                    },
                    {
                      key: 'image',
                      item: [
                        {
                          content: 'Image',
                        },
                        {
                          content: (
                            <a
                              href={notification.payload.image}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={notification.payload.image}
                                alt="Notification Image"
                                height="100"
                              />
                            </a>
                          ),
                        },
                      ],
                    },
                    {
                      key: 'launchURL',
                      item: [
                        {
                          content: 'Launch URL',
                        },
                        {
                          content: (
                            <a
                              href={notification.payload.data.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {notification.payload.data.url}
                            </a>
                          ),
                        },
                      ],
                    },
                  ].concat(actionButtons)}
                />
              </Card>
            </Grid.Col>
          </Grid.Row>
        );
      },
    );
    return (
      <Page.Content>
        <Page.Header>
          <Page.Title>{this.state.message.title}</Page.Title>
          <Text style={{ width: '100%' }}>
          <strong>Create At:</strong> {moment(
              this.state.message.createdAt.replace('.000Z', '-00:00'),
            ).format('lll')}
          </Text>
          {this.state.message.scheduleAt  &&  <Text style={{ width: '100%' }}>
            <strong>Schedule To:</strong> {moment(
              this.state.message.scheduleAt.replace('.000Z', '-00:00'),
            ).format('lll')}
          </Text>

          }
        </Page.Header>
        {alertDeliveryMethod}
        {alertStatistics}
        <Grid.Row cards={true}>
          <Grid.Col width={3}>
            <StampCard
              header={
                this.state.stats.sent === 0
                  ? '...'
                  : this.state.stats.sent.toLocaleString(undefined, 2)
              }
              footer="Notifications Sent"
              icon="send"
              color={this.state.message.status === 'Sent' ? 'green' : 'warning'}
            />
          </Grid.Col>
          <Grid.Col width={3}>
            <StampCard
              header={
                this.state.stats.received === 0
                  ? '...'
                  : this.state.stats.received.toLocaleString(undefined, 2)
              }
              footer="Users Received"
              icon="check"
              color="blue"
            />
          </Grid.Col>
          <Grid.Col width={3}>
            <StampCard
              header={
                this.state.stats.clicked === 0
                  ? '...'
                  : this.state.stats.clicked.toLocaleString(undefined, 2)
              }
              footer="Users Clicked"
              icon="crosshair"
              color="blue"
            />
          </Grid.Col>
          <Grid.Col width={3}>
            <StampCard
              header={
                this.state.stats.ctr === 0 ? '...' : this.state.stats.ctr + '%'
              }
              footer="CTR"
              icon="trending-up"
              color="blue"
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Delivery Statistics</Card.Title>
              </Card.Header>
              {deliveryChart}
              <Table
                responsive
                className="card-table table-vcenter text-nowrap"
                headerItems={[
                  { content: 'Platform' },
                  { content: 'Delivery' },
                  { content: 'Interactions' },
                  { content: 'CTR' },
                ]}
                bodyItems={this.state.message.notifications.map(
                  (notification, i) => ({
                    key: notification.id,
                    item: [
                      {
                        content: (
                          <React.Fragment>
                            <span
                              className={
                                'status-icon bg-' +
                                HelperNotification.getApplicationType(
                                  notification.application.type,
                                ).color
                              }
                            />
                            {
                              HelperNotification.getApplicationType(
                                notification.application.type,
                              ).label
                            }
                          </React.Fragment>
                        ),
                      },
                      {
                        content: (
                          <React.Fragment>
                            <div>
                              {notification.statsReceived.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              received
                            </div>
                            <Text size="sm" muted>
                              {notification.statsSent.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              sent
                            </Text>
                          </React.Fragment>
                        ),
                      },
                      {
                        content: (
                          <React.Fragment>
                            <div>
                              {notification.statsClicked.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              clicked
                            </div>
                            <Text size="sm" muted>
                              {notification.statsDismissed.toLocaleString(
                                undefined,
                                2,
                              )}{' '}
                              dismissed
                            </Text>
                          </React.Fragment>
                        ),
                      },
                      {
                        content: (
                          <React.Fragment>
                            {Math.round(
                              (notification.statsClicked /
                                notification.statsReceived) *
                                10000,
                            ) / 100}
                            %
                          </React.Fragment>
                        ),
                      },
                    ],
                  }),
                )}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
        {notificationDetails}
      </Page.Content>
    );
  }
}
