import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'tabler-react/dist/Tabler.css';

import ComposePage from './pages/ComposePage';
import DashboardPage from './pages/DashboardPage';
import DocumentationPage from './pages/DocumentationPage';
import AuthPage from './pages/AuthPage';
import MessagePage from './pages/MessagePage';
import MessagesPage from './pages/MessagesPage';
import OrganizationPage from './pages/OrganizationPage';
import ProductSettingsPage from './pages/ProductSettingsPage';
import SiteWrapper from './SiteWrapper';

import configureStore from './store';

const { store, persistor } = configureStore();

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <Route exact path="/auth/:action" component={AuthPage} />
          <SiteWrapper>
            <Route exact path="/" component={DashboardPage} />
            <Route exact path="/organization" component={OrganizationPage} />
            <Route exact path="/documentation" component={DocumentationPage} />
            <Route exact path="/products/:product/compose" component={ComposePage}/>
            <Route exact path="/products/:product/messages" component={MessagesPage} />
            <Route exact path="/products/:product/messages/:message" component={MessagePage}/>
            <Route exact path="/products/:product/settings" component={ProductSettingsPage}/>
          </SiteWrapper>
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
