import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import AuthManager from './managers/AuthManager';

import './assets/css/index.css';
import './assets/css/c3jscustom.css';

new AuthManager().setAuthorizationHeaders();

ReactDOM.render(<App />, document.getElementById('root'));
