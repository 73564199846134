import * as React from 'react';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Grid, Page, RouterContextProvider, Site } from 'tabler-react';

import logo from './assets/images/comet-logo.png';
import Loading from './components/Loading';
import HelperUtils from './helpers/utils';
import APIManager from './managers/APIManager';

const version = process.env.REACT_APP_VERSION;

class SiteWrapper extends React.Component {
  state = {
    serviceStatus: null,
  };

  componentWillMount() {
    APIManager.getServiceStatus().then(res =>
      this.setState({
        serviceStatus: res.data,
      }),
    );
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;

    if (!isAuthenticated) {
      return <Redirect to="/auth/login" />;
    }

    if (this.state.serviceStatus === null) {
      return <Loading />;
    } else if (this.state.serviceStatus.status !== 1) {
      return (
        <Page.Content className="mt-0">
          <Grid.Row cards={true}>
            <Grid.Col offset={3} width={6}>
              <Page.Header />
              <Card statusColor="red">
                <Card.Header>
                  <Card.Title>Service Temporarily Unavailable</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>
                    Comet is temporarily unavailable and will be back shortly.
                  </p>
                  <p>You may find the maintenance announcement below.</p>
                  <blockquote>{this.state.serviceStatus.message}</blockquote>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      );
    }

    let navBarItems;
    if (user.role === 'OWNER') {
      navBarItems = [
        {
          value: 'Dashboard',
          to: '/',
          icon: 'home',
          LinkComponent: withRouter(NavLink),
          useExact: true,
        },
        {
          value: 'Organization',
          to: '/organization',
          icon: 'briefcase',
          LinkComponent: withRouter(NavLink),
          useExact: true,
        },
        {
          value: 'Documentation',
          to: '/documentation',
          icon: 'file-text',
          LinkComponent: withRouter(NavLink),
          useExact: true,
        },
      ];
    } else {
      navBarItems = [
        {
          value: 'Dashboard',
          to: '/',
          icon: 'home',
          LinkComponent: withRouter(NavLink),
          useExact: true,
        },
        {
          value: 'Documentation',
          to: '/documentation',
          icon: 'file-text',
          LinkComponent: withRouter(NavLink),
          useExact: true,
        },
      ];
    }

    return (
      <Site.Wrapper
        headerProps={{
          href: '/',
          alt: 'Comet',
          imageURL: logo,
          accountDropdown: {
            avatarURL: HelperUtils.getGravatarURL(user.email),
            name: user.name,
            description: user.organization.name,
            options: [
              { icon: 'log-out', to: '/auth/logout', value: 'Log Out' },
            ],
          },
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2023 Empower. All rights reserved.
            </React.Fragment>
          ),
          nav: <React.Fragment>v{version}</React.Fragment>,
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(SiteWrapper);
