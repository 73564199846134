import { useState, useEffect } from 'react';
import APIManager from '../managers/APIManager';
import HelperMessage from '../helpers/message';

const usePagination = (product, initialPage = 0, itemsPerPage = 15) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [mes, setMes] = useState([]);
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        setLoading(true);
        APIManager.getMessages(product, currentPage - 1, itemsPerPage)
          .then(res => {
            let messages = res.data.messages;
            messages.forEach((_message, i) => {
              messages[i].statsClicked = 0;
              messages[i].statsDismissed = 0;
              messages[i].statsReceived = 0;
              messages[i].statsSent = 0;
              messages[i].status = 'Sent';
              messages[i].deliveryMethod = HelperMessage.getDeliveryMethod(
                messages[i],
              );
              _message.notifications.forEach(_notification => {
                messages[i].statsClicked += _notification.statsClicked;
                messages[i].statsDismissed += _notification.statsDismissed;
                messages[i].statsReceived += _notification.statsReceived;
                messages[i].statsSent += _notification.statsSent;
                if (
                  _notification.partitionsTargeted.length >
                  _notification.partitionsSent.length
                ) {
                  messages[i].status = 'Sending';
                }
              });
            });

            setMes(messages);
            setProductData(res.data);
            setLoading(false);
          })
          .catch(err => {
            setError(err);
            setLoading(false);
          });
      };

      fetchData();
    },
    [currentPage, itemsPerPage, refresh],
  );

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  return {
    currentPage,
    mes,
    productData,
    loading,
    error,
    paginate,
    setRefresh,
  };
};

export default usePagination;
