import { Cancel, SaveOutlined } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import moment from 'moment';
import { Card, Grid, Page, Form } from 'tabler-react';
import APIManager from '../managers/APIManager';
const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  width: '40rem',
  maxWidth: '100vw',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const EditModal = ({
  isOpen,
  onRequestClose,
  onSave,
  item,
  setOpenSnackbar,
  setOpe,
  setSnackbarMessage,
}) => {
  if (!isOpen) {
    return null;
  }

  const [title, setTitle] = useState(item.title ? item.title : '');
  const [content, setContent] = useState(
    item.notifications.length && item.notifications[0].payload.body
      ? item.notifications[0].payload.body
      : '',
  );
  const [scheduleAt, setScheduleAt] = useState(
    item.scheduleAt ? item.scheduleAt : '',
  );

  const handleSave = () => {
    const contentTmp = content.length ? content : title;
    const scheduleTmp = scheduleAt.length
      ? moment(scheduleAt)
          .tz('Asia/Istanbul')
          .utc()
          .format('YYYY-MM-DD HH:mm:00')
      : '';
    const newItem = {
      title,
      content: contentTmp,
      scheduleAt: scheduleTmp,
    };
    APIManager.updateMessage(item.id, newItem)
      .then(res => {
        if (res.errors.length > 1) {
          setSnackbarMessage(0);
          setOpenSnackbar(1);
        } else {
          setSnackbarMessage(1);
          setOpenSnackbar(1);
        }
      })
      .catch(err => console.error(err));
    onSave();
    onRequestClose();
  };

  return (
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <Grid.Row cards={true}>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Card.Title>Edit Message</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group
                    label={
                      <Form.Label
                        aside={title.length + ' characters'}
                        children="Title"
                      />
                    }
                  >
                    <Form.Input
                      placeholder="Notification Title"
                      onChange={e => e && setTitle(e.target.value)}
                      value={title}
                    />
                  </Form.Group>
                  <Form.Group
                    label={
                      <Form.Label
                        aside={content.length + ' characters'}
                        children="Content"
                      />
                    }
                  >
                    <Form.Textarea
                      placeholder="Notification Content"
                      onChange={e => e && setContent(e.target.value)}
                      value={content}
                    />
                  </Form.Group>
                  <Form.Group label="Schedule">
                    <Form.MaskedInput
                      placeholder="0000-00-00 00:00"
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        ':',
                        /\d/,
                        /\d/,
                      ]}
                      onChange={e => e && setScheduleAt(e.target.value)}
                      value={scheduleAt}
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <div style={{ float: 'right' }}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={handleSave}
              startIcon={<SaveOutlined />}
            >
              Save
            </Button>
            <Button
              onClick={onRequestClose}
              variant="contained"
              startIcon={<ClearOutlinedIcon />}
            >
              Cancel
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
