import { Cancel } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { Cat } from 'react-kawaii';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  width: '500px',
  maxWidth: '100vw',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const DeleteConfirmationModal = ({
  isOpen,
  onRequestClose,
  onDelete,
  item,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <div style={{ display: 'flex' }}>
          <Cat
            size={200}
            mood="sad"
            color="#596881"
            style={{ margin: '10px' }}
          />
          <div style={{ padding: '15px' }}>
            <h2>The message below will be deleted:</h2>
            <p> "{item.title}"</p>
            <p>
              Scheduled for:{' '}
              {moment(item.scheduleAt.replace('.000Z', '-00:00')).format('lll')}
            </p>
          </div>
        </div>
        <div style={{ float: 'right' }}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={onDelete}
              startIcon={<DeleteOutlineOutlinedIcon />}
            >
              Delete
            </Button>
            <Button
              onClick={onRequestClose}
              variant="contained"
              startIcon={<ClearOutlinedIcon />}
            >
              Cancel
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
