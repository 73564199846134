import * as React from 'react';
import { Planet } from 'react-kawaii';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Error403Page, Grid, Page } from 'tabler-react';

class ProductSettingsPage extends React.Component {
  render() {
    const { user } = this.props.auth;

    if (user.role !== 'OWNER') {
      return <Redirect to="/auth/login" />;
    }

    return (
      <Page.Content title="Settings">
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Error403Page details={<Planet size={200} mood="excited" color="#E0E5EC" />} title="Coming Soon" subtitle="We're still working on this, please visit later!" />
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(ProductSettingsPage);